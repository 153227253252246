export * from './modalNames';
export * from './navigationParents';

export const DEFAULT_IMAGE = 'https://cdn11.bigcommerce.com/s-hcp6qon/stencil/b01010e0-8f17-0138-8846-0242ac110011/icons/icon-no-image.svg';

export const SortOrders = Object.freeze({
  ASC: 'ASC',
  DESC: 'DESC'
});

export const Roles = Object.freeze({
  MASTER_ADMIN: 'MASTER_ADMIN',
  TEC_SUPPORT: 'TEC_SUPPORT',
  COMPANY_ADMIN: 'COMPANY_ADMIN',
  COMPANY_USER: 'COMPANY_USER',
  SALES_LEAD: 'SALES_LEAD',
  SALES_USER: 'SALES_USER',
  CLIENT: 'CLIENT',
});

export const ProjectStatuses = Object.freeze({
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED'
});

export const ProjectBuildingStatuses = Object.freeze({
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED'
});

export const ProjectAvailabilities = Object.freeze({
  UNDER_DEVELOPMENT: 'UNDER_DEVELOPMENT',
  AVAILABLE: 'AVAILABLE',
  COMING_SOON: 'COMING_SOON'
});

export const ProjectBuildingAvailabilities = Object.freeze({
  AVAILABLE: 'AVAILABLE',
  COMING_SOON: 'COMING_SOON'
});

export const UnitAvailabilities = Object.freeze({
  AVAILABLE: 'AVAILABLE',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
  COMING_SOON: 'COMING_SOON',
  SOLD: 'SOLD',
  RENTED: 'RENTED'
});

export const TaxRateTypes = Object.freeze({
  COMMUNITY_PROJECT_BASED: 'COMMUNITY_PROJECT_BASED',
  CITY_BASED: 'CITY_BASED'
});

export const Statuses = Object.freeze({
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED'
});

export const DateFormats = Object.freeze({
  MMM_DD_YYYY_HH_MM_SS: 'MMM DD, YYYY HH:mm:ss',

  // Jun 20, 2022 17:02
  MMM_DD_YYYY_HH_MM: 'MMM DD, YYYY HH:mm',

  // Jun 20, 2022 5:02 PM
  MMM_DD_YYYY_H_MM_A: 'MMM DD, YYYY h:mm A',

  // Nov 07, 2021
  MMM_DD_YYYY: 'MMM DD, YYYY',

  // 07 Nov 2021
  DD_MMM_YYYY: 'DD MMM YYYY',

  // 2022-05-21
  YYYY_MM_DD: 'YYYY-MM-DD',

  // 05-21-2022
  MM_DD_YYYY_2: 'YYYY-MM-DD',

  MMM_dd_yyyy: 'MMMdd, yyyy',

  // 14:59:35
  HH_MM_SS: 'HH:mm:ss',

  // 5:35pm
  H_MM_A: 'h:mm a',

  // 07/24/2021
  MM_DD_YYYY: 'MM/DD/YYYY',

  // Dec 14, 2022 at 15:00 PM MST
  MMM_DD_YYYY_HH_MM_Z: 'MMM DD, YYYY [at] h:mm A z',

  // October
  MMMM: 'MMMM',

  // Oct
  MMM: 'MMM',
});

export const ScenarioModes = Object.freeze({
  SINGLE_FAMILY: 'SINGLE_FAMILY',
  MULTI_FAMILY: 'MULTI_FAMILY'
});

export const ViewModes = Object.freeze({
  GRID: 'GRID',
  LIST: 'LIST'
});

export const QuickPossessionAvailabilities = Object.freeze({
  AVAILABLE: 'AVAILABLE',
  HOLD: 'HOLD',
  SOLD: 'SOLD'
});

export const QuickPossessionStatuses = Object.freeze({
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
  ARCHIVED: 'ARCHIVED'
});

export const SalesSimplicityApiUrl = 'http://api1.salessimplicity.net/';
export const MarondaHomesApiUrl = 'https://api.maronda.com/';

export const EXTERNAL_DATA_SOURCE_STATUSES = Object.freeze({
  waiting: 'WAITING',
  processing: 'PROCESSING',
  successful: 'SUCCESSFUL',
  failed: 'FAILED'
});

export const ExternalDataSourceProviders = Object.freeze({
  SALES_SIMPLICITY: 'salesSimplicity',
  MARONDA_HOMES: 'marondaHomes'
});

export const LotWorksStandardProductTypes = Object.freeze({
  SINGLE_FAMILY_FRONT: { label: 'Single Family Front', value: 1 },
  SINGLE_FAMILY_REAR: { label: 'Single Family Rear', value: 2 },
  SINGLE_FAMILY_FRONT_ESTATE: { label: 'Single Family Front - Estate', value: 3 },
  SINGLE_FAMILY_FRONT_ZERO_LOT_LINE: { label: 'Single Family Front - Zero Lot Line', value: 4 },
  SINGLE_FAMILY_REAR_ZERO_LOT_LINE: { label: 'Single Family Rear - Zero Lot Line', value: 5 },
  DUPLEX_FRONT: { label: 'Duplex Front', value: 20 },
  DUPLEX_REAR: { label: 'Duplex Rear', value: 21 },
  ROW_HOME: { label: 'Row Home', value: 40 },
  TOWNHOME: { label: 'Townhome', value: 41 },
  CONDO: { label: 'Condo', value: 60 },
  OTHER: { label: 'Other', value: 99 },
});

export const FloorOptionLogicalOperators = Object.freeze({
  OR: 'OR',
  AND: 'AND'
});

export const WebSocketMessages = Object.freeze({
  EXTERNAL_DATA_SOURCE_SYNC: 'externalDataSourceSync',
  VERIFIED_CONTACT_REVIEWED: 'verifiedContactReviewed',
  CLIENT_MESSAGE_READ: 'clientMessageRead',
  HUB_SPOT_CLIENT_AUTHORIZED: 'hubSpotClientAuthorized',
  IMPORT_TEMPLATE: 'importTemplate',
  CUSTOMERS_EXPORT: 'customersExport'
});

export const WebhookEvents = Object.freeze({
  CONTACT_CREATED: 'contact.created',
  BUILD_CREATED: 'build.created'
});

export const WebhookDeliveryStatuses = Object.freeze({
  PENDING: 'pending',
  DELIVERED: 'delivered',
  FAILED: 'failed'
});

export const FloorableTypes = Object.freeze({
  MODEL: 'Model',
  BUILDING_MODEL: 'BuildingModel',
  QUICK_POSSESSION: 'QuickPossession'
});

export const ValidUntilPeriods = Object.freeze([
  { label: 'Day(s)', value: 'days' },
  { label: 'Week(s)', value: 'weeks' },
  { label: 'Month(s)', value: 'months' }
]);

export const SalableTypes = Object.freeze({
  COMMUNITY: 'Community',
  COMMUNITY_PRODUCT_TYPE: 'CommunityProductType',
  PROJECT: 'Project'
});

export const SellerTypes = Object.freeze({
  SALES_REP: 'User',
  SALES_TEAM: 'Team',
});

export const OfferStatuses = Object.freeze({
  SUBMITTED: 'SUBMITTED',
  ACCEPTED: 'ACCEPTED',
  COUNTERED: 'COUNTERED',
  RECOUNTERED: 'RECOUNTERED',
  REJECTED: 'REJECTED',
  DECLINED: 'DECLINED',
  INVITED: 'INVITED'
});

export const OfferSignStatuses = Object.freeze({
  SUBMITTED: 'SUBMITTED',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  DECLINED: 'DECLINED'
});

export const DepositStatuses = Object.freeze({
  SUBMITTED: 'SUBMITTED',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
});

export const DepositPayoutStatuses = Object.freeze({
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED'
});

export const PaymentVendors = Object.freeze({
  STRIPE: 'STRIPE',
  VOPAY: 'VOPAY',
  MANUAL: 'MANUAL'
});

export const PaymentMethods = Object.freeze({
  CARD: 'CARD',
  ACH_DIRECT_DEBIT: 'ACH_DIRECT_DEBIT'
});

export const PreApprovalVerificationMethods = Object.freeze({
  HOMEWISE: 'HOMEWISE',
  MANUAL: 'MANUAL'
});

export const PreApprovalStatuses = Object.freeze({
  PENDING: 'PENDING',
  VALIDATED: 'VALIDATED',
  REJECTED: 'REJECTED',
});

export const IdVerificationProviders = Object.freeze({
  ONFIDO: 'ONFIDO',
  SECURE_KEY: 'SECURE_KEY',
  PLAID: 'PLAID'
});

export const ProviderNames = Object.freeze({
  docusign: 'DocuSign',
});

export const PriceableTypes = Object.freeze({
  PACKAGE: 'Package',
  SPECIFICATION: 'Specification',
  PALETTE: 'Palette',
  ELEVATION: 'Elevation',
  FLOOR_OPTION: 'FloorOption',
});

export const PRICE_RULE_SEPARATOR = ' » ';

export const LotMapProviders = Object.freeze({
  LOT_WORKS: 'LOT_WORKS',
  FOCUS_360: 'FOCUS_360',
  STREETSCAPE: 'STREETSCAPE'
});

export const FloorPlanProviders = Object.freeze({
  OWNLY: 'OWNLY',
  FOCUS_360: 'FOCUS_360'
});

export const EmailServices = Object.freeze({
  AWS_SES: 'AWS_SES',
  SENDGRID: 'SENDGRID'
});

export const HomePriceDisplayModes = Object.freeze({
  TOTAL_PRICE_ONLY: 'TOTAL_PRICE_ONLY',
  MONTHLY_PAYMENT_AND_TOTAL_PRICE: 'MONTHLY_PAYMENT_AND_TOTAL_PRICE',
  MONTHLY_PAYMENT_OR_TOTAL_PRICE: 'MONTHLY_PAYMENT_OR_TOTAL_PRICE',
  MONTHLY_PAYMENT_ONLY: 'MONTHLY_PAYMENT_ONLY'
});

export const DownPaymentTypes = Object.freeze({
  AMOUNT: 'AMOUNT',
  PERCENT: 'PERCENT'
});

export const SocialMediaProviders = Object.freeze({
  FACEBOOK: 'FACEBOOK',
  APPLE: 'APPLE',
  GOOGLE: 'GOOGLE'
});

export const DocuSignTemplateTypes = Object.freeze({
  UPLOAD_DOCUMENT: 'UPLOAD_DOCUMENT',
  SELECT_TEMPLATE: 'SELECT_TEMPLATE'
});

export const DEFAULT_NULL_STRING_VALUE = '--';

export const MappableTypes = Object.freeze({
  COMMUNITY: 'Community',
  MODEL: 'Model'
});

export const Currencies = Object.freeze({
  USD: 'usd',
  CAD: 'cad'
});

export const PRICE_RULE_DEFAULT_SELECTIONS = Object.freeze({
  OVERRIDE_PARENT: 'OVERRIDE_PARENT',
  SAME_AS_PARENT: 'SAME_AS_PARENT',
});

export const FloorOptionAvailabilityRules = Object.freeze({
  AVAILABLE: 'AVAILABLE',
  UNAVAILABLE: 'UNAVAILABLE'
});

export const FloorOptionAvailabilityRuleTypes = Object.freeze({
  COMMUNITIES: 'COMMUNITIES',
  COMMUNITY_PHASES: 'COMMUNITY_PHASES'
});

export const MappableFields = Object.freeze({
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  FULL_NAME: 'fullName',
  EMAIL: 'email',
  PHONE: 'phone',
  MESSAGE: 'message'
});

export const ContactStages = Object.freeze({
  LEAD: 'LEAD',
  BUYER: 'BUYER',
  CLOSED: 'CLOSED',
  NON_CUSTOMER: 'NON_CUSTOMER'
});

export const TrafficSourceTypes = Object.freeze({
  DIRECT: 'DIRECT',
  REFERRAL: 'REFERRAL',
  ORGANIC_SEARCH: 'ORGANIC_SEARCH',
  PAID_SEARCH: 'PAID_SEARCH',
  SOCIAL_MEDIA: 'SOCIAL_MEDIA',
  EMAIL: 'EMAIL',
  OTHER: 'OTHER'
});

export const SearchEngineDomains = Object.freeze({
  GOOGLE: ['google.com', 'google.ca', 'google.co'],
  BRAVE: ['search.brave.com'],
  YAHOO: ['search.yahoo.com'],
  DUCKDUCKGO: ['duckduckgo.com'],
  BING: ['bing.com']
});

export const SocialMediaDomains = Object.freeze({
  FACEBOOK: ['facebook.com', 'fb.com', 'fb.me'],
  LINKED_IN: ['linkedin.com'],
  INSTAGRAM: ['instagram.com'],
  TWITTER: ['twitter.com', 't.co'],
  YOUTUBE: ['youtube.com']
});

export const TrackingSources = Object.freeze({
  OWNLY: 'OWNLY',
  WEBTRACKER: 'WEBTRACKER',
  WIDGET: 'WIDGET'
});

export const IdDocumentTypes = Object.freeze({
  NATIONAL_IDENTITY_CARD: 'national_identity_card',
  PASSPORT: 'passport',
  BANK: 'bank',
  DRIVING_LICENCE: 'driving_licence',
  DRIVERS_LICENSE: 'drivers_license',
  VOTER_ID: 'voter_id',
  IDENTITY_CARD: 'identity_card',
  WORK_PERMIT: 'work_permit',
  RESIDENCE_PERMIT: 'residence_permit'
});

export const DefaultHomePage = Object.freeze({
  MY_HOMES: 'MY_HOMES',
  NEW_HOME: 'NEW_HOME',
  GET_VERIFIED: 'GET_VERIFIED',
});

export const CustomerSortFields = Object.freeze({
  NAME: 'name',
  SIGN_UP_DATE: 'signUpDate',
  LAST_ACTIVE: 'lastActive',
  TYPE: 'verificationDate',
  BUYING_POWER: 'buyingPower',
  RENTAL_POWER: 'rentalPower',
  HOME_VALUATION: 'homeValuation',
  BROWSING_PRICE: 'browsingPrice',
  VERIFICATION_PROGRESS: 'verificationProgress',
  RESERVATION_PROGRESS: 'reservationProgress',
});

export const FinancialTrends = Object.freeze({
  NONE_DETECTED: 'NONE_DETECTED',
  INCREASING: 'INCREASING',
  DECREASING: 'DECREASING',
  CONSISTENT: 'CONSISTENT',
});

export const Colors = Object.freeze({
  GRAY_100: '#F2F4F7',
  GRAY_200: '#EAECF0',
  GRAY_300: '#D0D5DD',
  GRAY_400: '#98A2B3',
  GRAY_500: '#667085',
  GRAY_600: '#475467',
  GRAY_700: '#344054',
  PRIMARY_50: '#DDF7F0',
  PRIMARY_100: '#ACEAD8',
  PRIMARY_200: '#70DDBF',
  PRIMARY_300: '#00CEA6',
  PRIMARY_400: '#00C191',
  PRIMARY_500: '#00B37F',
  PRIMARY_600: '#00A572',
  PRIMARY_700: '#009462',
  INFO: '#74C0FC',
  WARNING_200: '#FEDF89',
  DANGER_700: '#B42318'
});

export const WorkflowTypes = Object.freeze({
  BUYER_RESERVE: 'BUYER_RESERVE',
  BUYER_VERIFIED: 'BUYER_VERIFIED',
  RENTAL_RESERVE: 'RENTAL_RESERVE',
  RENTAL_VERIFIED: 'RENTAL_VERIFIED',
});

export const VerificationTypes = Object.freeze({
  ID_VERIFICATION: 'idVerification',
  HOME_EVALUATION: 'homeEvaluation',
  FINANCIAL_VERIFICATION: 'financialVerification',
});

export const DEFAULT_RENTAL_VERIFIED_WORKFLOW = Object.freeze([
  {
    type: VerificationTypes.ID_VERIFICATION,
    optional: false
  },
  {
    type: VerificationTypes.FINANCIAL_VERIFICATION,
    optional: false
  }
]);

export const DEFAULT_BUYER_VERIFIED_WORKFLOW = Object.freeze([
  {
    type: VerificationTypes.ID_VERIFICATION,
    optional: false
  },
  {
    type: VerificationTypes.HOME_EVALUATION,
    optional: true
  },
  {
    type: VerificationTypes.FINANCIAL_VERIFICATION,
    optional: false
  }
]);

export const TeamUserPermissions = Object.freeze({
  MEMBER: 'MEMBER',
  LEAD: 'LEAD',
  MANAGER: 'MANAGER'
});

export const ReserveStepTypes = Object.freeze({
  OFFER: 'offer',
  SIGN: 'sign',
  DEPOSIT: 'deposit',
});

export const EMAIL_SMS_VERIFICATION = 'emailSmsVerification';

export const CustomerProgressTypes = Object.freeze({
  VERIFICATION: 'verification',
  RESERVATION: 'reservation',
});

export const TrackingEventTypes = Object.freeze({
  SELECT_COMMUNITY: 'select_community',
  SELECT_MODEL: 'select_model',
  SELECT_PROJECT: 'select_project',
  SELECT_UNIT: 'select_unit',
  SELECT_BUILDING_MODEL: 'select_building_model',
  SELECT_QUICK_POSSESSION: 'select_quick_possession',
});

export const CustomerTabs = Object.freeze({
  ACTIVITY: 'activity',
  VERIFICATION: 'verification',
  DEALS: 'deals',
  MESSAGES: 'messages',
  DOCUMENTS: 'documents',
  INTERESTS: 'interests'
});

export const SalesInvitationStartTypes = Object.freeze({
  FROM_CUSTOMER: 'fromCustomer',
  FROM_HOME: 'fromHome'
});

export const LottieAnimationsUrl = Object.freeze({
  LOADING_HOME: 'https://lottie.host/4c42e993-ed0e-4a18-b112-1a57e867bfb4/Srq2NUL1lR.json'
});

export const MortgageProviderAssignableTypes = Object.freeze({
  COMMUNITY: 'Community',
  PROJECT: 'Project'
});

export const QuickPossessionStages = Object.freeze({
  ACTIVE: 'ACTIVE',
  SCHEDULED_TO_ARCHIVE: 'SCHEDULED_TO_ARCHIVE',
  archived: 'ARCHIVED'
});

export const DepositsSortFields = Object.freeze({
  DATE: 'createdAt'
});

export const CreditCardBrands = Object.freeze({
  VISA: 'visa',
  MASTER_CARD: 'mastercard',
  AMERICAN_EXPRESS: 'amex',
  DISCOVER: 'discover',
  DINERS: 'diners',
  UNION_PAY: 'unionpay',
  JCB: 'jcb'
});

export const RefundStatuses = Object.freeze({
  PENDING: 'PENDING',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
  CANCELED: 'CANCELED'
});

export const RefundReasons = Object.freeze({
  DEAL_COMPLETED: 'dealCompleted',
  DEAL_CANCELED: 'dealCanceled',
  OTHER: 'other'
});

export const ProjectBuildAndPriceStartingPointTypes = Object.freeze({
  MODEL: 'MODEL',
  UNIT: 'UNIT'
});

export const DocumentTemplateAssignableEntities = Object.freeze({
  COMMUNITY: 'Community',
  PROJECT: 'Project',
  CITY: 'City'
});

export const DocumentTemplateTypes = Object.freeze({
  UPLOAD_DOCUMENT: 'UPLOAD_DOCUMENT',
  SELECT_TEMPLATE: 'SELECT_TEMPLATE'
});

export const QuickPossessionColumns = Object.freeze({
  COMMUNITY: 'community',
  MODEL: 'model',
  PRODUCT_TYPE: 'productType',
  ADDRESS: 'address',
  SIZE: 'size',
  BEDS: 'beds',
  BATHS: 'baths',
  PRICE: 'price',
  AVAILABILITY: 'availability',
  STATUS: 'status',
  INTERESTS: 'interests',
  LAST_MODIFIED: 'lastModified',
  LAST_CREATED: 'lastCreated'
});

export const DefaultQuickPossessionColumns = Object.freeze([
  QuickPossessionColumns.COMMUNITY,
  QuickPossessionColumns.MODEL,
  QuickPossessionColumns.PRODUCT_TYPE,
  QuickPossessionColumns.ADDRESS,
  QuickPossessionColumns.SIZE,
  QuickPossessionColumns.BEDS,
  QuickPossessionColumns.BATHS,
  QuickPossessionColumns.PRICE,
  QuickPossessionColumns.AVAILABILITY
]);

export const DEFAULT_TIMEZONE = 'America/Boise';
